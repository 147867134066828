<template>
  <div>
    <div class="glo-nav">实时信息</div>
    <div class="info-data">
      <van-row type="flex" align="center">
        <van-col span="12">
          <div class="block-top block-pub">
            <div class="title">
              <div>
                <van-icon name="cluster-o" />
                <div>监测点</div>
              </div>
            </div>
            <div class="number">{{info.vib || 0}}</div>
          </div>
        </van-col>
        <van-col span="12">
          <div>
            <router-link to="/bridge/bjLab/sensor">
              <div class="block-btm block-pub clo1 glo-relative">
                <div class="title">
                    <van-icon name="hotel-o" />
                    <div>传感器</div>
                </div>
                <div class="number">{{info.vib || 0}}</div>
                <div class="link-relative"><van-icon name="arrow" /></div>
              </div>
            </router-link>
          </div>
          <van-row>
            <div>
              <router-link to="/bridge/bjLab/gateway">
                <div class="block-btm block-pub clo3 glo-relative">
                  <div class="title">
                      <van-icon name="desktop-o" />
                      <div>网关</div>
                  </div>
                  <div class="number">{{info.gw || 0}}</div>
                  <div class="link-relative"><van-icon name="arrow" /></div>
                </div>
              </router-link>
            </div>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <div class="monitor-bridge glo-relative">
      <img :src="pointBgImg">
      <div v-for="(item,index) in moudleData" :key="index" :class="item.status==0 ? 'radar-result-success' : 'radar-result-danger'" :style="{position: 'absolute',top:item.top,left:item.left}" @click="hintInfo(item.modCode)"></div>
    </div>
    <div :class="'table glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <van-row class="tb-head">
          <van-col span="6">监测点</van-col>
          <van-col span="6">倾角(°)</van-col>
          <van-col span="6">挠度(mm)</van-col>
          <van-col span="6">状态</van-col>
        </van-row>
        <div v-if="loading">
            <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
            <div v-if="areaData.length == 0">
                <van-divider>暂无记录</van-divider>
            </div>
            <div v-else>
              <van-row class="tb-body" v-for="(item,index) in areaData" :key="index">
                <van-col span="6">{{item.devName || '暂无名称'}}</van-col>
                <van-col span="6">{{item.xaxisangle}}</van-col>
                <van-col span="6">{{(item.vibamp / 1).toFixed(2)}}</van-col>
                <van-col span="6">
                  <van-tag type="success" v-if="item.status == '0'">正常</van-tag>
                  <van-tag type="danger" v-else>告警</van-tag>
                </van-col>
              </van-row>
            </div>
        </div>
    </div>
    <tabbar />
  </div>
</template>
<script>
import tabbar from '@/components/bridge/LABtabbar.vue'
export default {
  components: {
    tabbar
  },
  props: ['magbot'],
  data() {
    return {
      bridgeCode:'',
      areaData:[],
      moudleData:[],
      weather:{
        icon:'104'
      },
      loading:true,
      magbotVal:'',
      info:{
        gw:'',
        vib:'',
        rly:''
      },
      pointBgImg:null
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;
      }
  },
  methods:{
    // 获取实时监控列表
    loadList(){
      this.$api.BRIDGE.realTimeList({
        bridgeCode:this.bridgeCode
      }).then(d=>{
        if(Array.isArray(d)){
            this.areaData = d.filter( e => { return e.xaxisangle});
            this.moduleStatus();
            this.loading = false;
        }
      })
    },
    // 获取模块信息位置
    moduleList(){
      let data ={
        bridgeCode: this.bridgeCode,
        modType: "VIB"
      }
      this.$api.BRIDGE.getModuleInfo(data).then(d=>{
        if(Array.isArray(d)){
            this.moudleData = d;
            this.moudleData = d.filter( e => e.mobileCoordinates);
            this.moudleData.forEach((element,index) => {
              element.status = 0;
              let arr = element.mobileCoordinates.split(',');
              element.top = arr[0];
              element.left = arr[1];
            });
        }
      })
    },
    // 获取模块状态信息
    moduleStatus(){
      let sta = this.areaData.filter( e => e.status==1);
      for(let i=0;i<sta.length;i++){
        this.moudleData.forEach((e,index) => {
          e.modCode == sta[i].devId ? e.status = 1 : void 0;
        })
      }
    },
    // 获取网关和传感器数量
    devCountInfo(){
      this.$api.BRIDGE.getDevCount({
        bridgeCode:this.bridgeCode
      }).then(d => {
        this.info.gw = d.gw;
        this.info.vib = d.vib;
        this.info.rly = d.rly;
      })
    },
    // 监测桥梁点击显示实时信息
    hintInfo(id){
      if(this.areaData.length == 0 ){
        this.$toast('实时数据刷新中，请重试...');
        return false;
      }
      let oneself = this.areaData.filter( e => e.devId == id)[0];
      if(oneself.status=='0'){
        this.$notify({
          type:'success',
          message:oneself.devName+': 倾角为'+oneself.xaxisangle+'°，挠度为'+(oneself.vibamp / 1).toFixed(2)+'mm'
        })
      }else{
       this.$notify({
          message:oneself.devName+': 倾角为'+oneself.xaxisangle+'°，挠度为'+(oneself.vibamp / 1).toFixed(2)+'mm'
        })
      }
    },
    // 点位图
    getPointImg(){
      this.$api.NEWBRIDGE.getinodeBitmap({
        projectCode:window.sessionStorage.getItem('projectCode')
      }).then(d=>{
        if(d&&d.length>0){
          this.pointBgImg = d[0].wxPath;
        }
      })
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
      this.bridgeCode = window.sessionStorage.getItem('bridgeCode');
      this.getPointImg();
      if(this.bridgeCode){
        this.devCountInfo();
        this.moduleList();
        this.loadList();
      }
  }
}
</script>
<style lang="scss" scoped>
.monitor-bridge{
  padding: 0 4%;
  img{
    width: 100%;
    height: 200px;
    background-color: #ccc;
  }
  .radar-result-success {
    background: radial-gradient(rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #20ff4d 24.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 48%, #0dff3f 53%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 49%, #20ff4d 52%, rgba(32, 255, 77, 0) 50.2%);
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #20ff4d;
    overflow: hidden;
  }
  .radar-result-danger {
    background: radial-gradient(rgba(255, 33, 33, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #df2929 24.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #df2929 48%, #df2929 53%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #df2929 49%, #df2929 52%, rgba(32, 255, 77, 0) 50.2%);
    width: 1.5rem;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid #df2929;
    overflow: hidden;
  }
  .radar-result-success:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #00ff33 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  .radar-result-danger:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #df2929 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
  }
  @keyframes radar-beam {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes blips {
    14% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    14.0002% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    25% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
    }
    26% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
      opacity: 1;
    }
    100% {
      background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
      opacity: 0;
    }
  }
}
.info-data{
  padding:4%;
  .block-pub{
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #ccc;
  }
  .block-top{
    background-color: #39cfe8;
    height: 8rem;
    margin-right: 20px;
    .van-icon{font-size: 2rem;}
    .title{font-size: .75rem;display: flex;align-items: center;}
    .number{font-size: 3rem;padding-left: 4%;display: flex;align-items: center;}
  }
  .block-btm{
      .van-icon{font-size: 1.5rem;}
      .title{font-size: .55rem;}
      .number{font-size: 2rem;padding-left: 4%;}
  }
  .margin-space{margin: 6% 0 2% 0;}
  .clo1{background-color: #5d96ff;margin-bottom: 10%;}
  .clo3{background-color: #6acc68;}
}
.table{
  .tb-head{
    font-weight: bold;font-size: .95rem;
    background-color: #86d9e8;
    color: #ffffff;
    div{
      line-height: 2;
      padding: 8px 0;
    }
  }
  .tb-body{
    font-size: .85rem;
    border-bottom: 1px solid #fff;
    background-color: #c6f5e8;
    color: #464646;
    div{
      line-height: 2;
      padding: 8px 0;
    }
  }
}
.glo-relative{
  .link-relative{
    position: absolute;
    top: 38%;
    right: 4%;
    .van-icon{
      font-size: .8rem !important;
    }
  }
}
</style>